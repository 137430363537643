.table {
  background-color: var(--mantine-color-white);
  overflow: hidden;

  thead {
    background-color: var(--mantine-color-gray-0);
  }

  tbody:before {
    display: none !important; /* Stops Elastics table component from show a loading bar on nested tables */
  }

  & tr th:last-child:empty {
    width: 100%;
  }

  & td, & th {
    white-space: nowrap;
  }
}
