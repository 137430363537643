.table {

  @mixin larger-than $mantine-breakpoint-sm {
    border: 1px solid var(--mantine-color-gray-3);
    border-radius: var(--mantine-radius-md);
    overflow: hidden;

    :global(.euiTable) {
      font-size: var(--mantine-font-size-sm);

      :global(.euiTableCellContent) {
        min-height: 32px;
        padding: 0.5rem var(--mantine-spacing-xs);
      }

      :global(.euiTableRow:last-child .euiTableRowCell) {
        border-bottom: none;
      }
    }

    :global(.euiTableHeaderCell) {
      background-color: alpha(var(--mantine-color-black), 0.05);

      :global(.euiTableCellContent) {
        padding: var(--mantine-spacing-xs) var(--mantine-spacing-xs);
      }

      :global(.euiTableCellContent__text) {
        font-size: var(--mantine-font-size-sm);
      }
    }
  }

  @mixin smaller-than $mantine-breakpoint-sm {
    :global(.euiTableCellContent) {
      padding: 5px 8px;
      font-size: var(--mantine-font-size-sm);
    }

    :global(.euiTableRowCell__mobileHeader) {
      padding-top: 0 !important;
    }
  }

  :global(.euiTableRow-isExpandedRow) {
    background-color: #fff !important;

    :global(.euiTableCellContent) {
      padding: var(--mantine-spacing-xs);
    }
  }

  :global(.euiTableRowCell--isExpander .euiTableCellContent) {
    overflow: visible !important;
  }
}

.expander {
  @mixin larger-than $mantine-breakpoint-sm {
    padding: var(--mantine-spacing-xs) var(--mantine-spacing-xs) !important;
    padding-right: 0 !important;
  }
}
