.button {
  @mixin smaller-than $mantine-breakpoint-sm {
    padding-inline: 0 !important;
  }
}

.group {
  @mixin smaller-than $mantine-breakpoint-sm {
    flex: 1 !important;
  }
}
