.children {
  margin-left: 28px;
  padding-left: 0;

  > a {
    border-left: 1px solid var(--mantine-color-gray-4);

    &[data-active="true"] {
      border-left: 1px solid var(--mantine-color-blue-4);
    }
  }
}
