.root {
  position: relative;
  overflow: hidden;

  &[data-variant="default"] {
    background-color: rgb(241, 243, 245) !important;
  }
}

.default {
  background-color: var(--mantine-color-gray-1);
}

.light {
  background-color: var(--mantine-color-white);
}

.collapsable {
  cursor: pointer;
}
