.inline {
  display: flex;
  justify-content: space-between;
  align-items: center;

  label {
    margin-bottom: 0;
    color: var(--mantine-color-black);
  }
}
