.root {
  width: 100%;

  &[data-orientation="horizontal"] {

    & :global(.mantine-Tabs-list) {
      @mixin smaller-than $mantine-breakpoint-sm {
        flex-wrap: wrap;
        padding-bottom: 8px;
        gap: 8px;
      }
    }

    & :global(.mantine-Tabs-tab) {
      @mixin larger-than $mantine-breakpoint-sm {
        &[data-active] {
          background-color: var(--mantine-color-white);
        }
      }

      @mixin smaller-than $mantine-breakpoint-sm {
        border: 1px solid var(--mantine-color-gray-3);
        border-radius: var(--mantine-radius-sm);

        &:where([data-active]) {
          background-color: var(--mantine-color-blue-0);
          border-color: var(--mantine-color-blue-3);

          &:before {
            content: unset;
          }
        }
      }
    }
  }

  &:where([data-orientation="vertical"]) {
    & :global(.mantine-Tabs-tab) {
      border-radius: 0;

      &:where([data-active]) {
        background-color: var(--mantine-color-blue-0) !important;
      }
    }
  }

  & :global(.mantine-Tabs-tab[aria-invalid="true"]) {
    background-color: var(--mantine-color-red-0) !important;
    border-color: var(--mantine-color-red-2) !important;
    color: var(--mantine-color-red-8) !important;

    &:where([data-active]) {
      background-color: var(--mantine-color-red-1) !important;
      border-color: var(--mantine-color-red-5) !important;
      color: var(--mantine-color-red-8) !important;
    }
  }
}

.tabLabel {
  white-space: nowrap;
}

.panel {
  background-color: var(--mantine-color-white);
}
