.item {
  &[aria-invalid="true"] {
    border-color: var(--mantine-color-red-6) !important;

    &:not([data-active]) > button:first-child {
      background: var(--mantine-color-red-0);
      border-radius: var(--mantine-radius-sm);
    }
  }
}

.control {
  padding-left: 1rem;
}
