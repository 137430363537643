.navbar {
  background-color: var(--mantine-color-white);
  padding-bottom: 0;
}

.section {
  padding: var(--mantine-spacing-xs) var(--mantine-spacing-md);
  margin-left: calc(var(--mantine-spacing-md) * -1);
  margin-right: calc(var(--mantine-spacing-md) * -1);
  color: var(--mantine-color-black);
  border-bottom: 1px solid var(--mantine-color-gray-3)
}

.footer {
  margin-left: calc(var(--mantine-spacing-md) * -1);
  margin-right: calc(var(--mantine-spacing-md) * -1);
  border-top: 1px solid var(--mantine-color-gray-3);
}

.sidebarDropdown {
  &:hover {
    cursor: pointer;

    & :global(.highlightText) {
      color: var(--mantine-color-black);
    }
  }
}

.highlightText {
  transition: color .3s linear;
  line-height: 1.2
}
