.content {
  font-size: 0.875rem;

  & :global(.ProseMirror) {
    padding: 0.5rem !important;
  }
}

.toolbar {
  padding: 0.5rem;
}

.root {
  ul, ol {
    padding-left: 1.5rem;
  }

  ul {
    list-style: disc;
  }

  ol {
    list-style: decimal;
  }

  &[data-invalid] {
    border-color: var(--mantine-color-red-6);
  }

  &[data-vertical-toolbar] {
    display: flex;

    > :first-child {
      border-bottom: 0;
      border-top-right-radius: 0;
      border-bottom-left-radius: var(--mantine-radius-sm);
      border-right: 0.0625rem solid var(--mantine-color-gray-4);
      align-items: start;
      padding: 0.5rem;

      > :first-child {
        flex-direction: column;

        > button {
          border: 0.0625rem solid var(--mantine-color-gray-4);

          &:not(:first-child):not(:last-child) {
            border-top: 0;
          }

          &:not(:only-child):first-child {
            border-bottom-left-radius: 0;
            border-top-right-radius: var(--mantine-radius-sm);
          }

          &:not(:only-child):last-child {
            border-top-right-radius: 0;
            border-bottom-left-radius: var(--mantine-radius-sm);
            border-top: 0;
          }
        }
      }
    }
  }
}
