.root {
  p {
    margin-bottom: var(--mantine-spacing-xs) !important;
  }

  p:last-child {
    margin-bottom: 0 !important;
  }

  ul, ol {
    padding-left: var(--mantine-spacing-xl) !important;

    &:last-child {
      margin-bottom: 0 !important;
    }

    li {
      margin-bottom: 0.35rem !important;
    }
  }

  ul {
    list-style: disc;
  }

  ol {
    list-style: decimal;
  }
}
