.filter {
  background-color: var(--mantine-color-white) !important;
  box-shadow: inset 0 0 0 1px var(--mantine-color-gray-3) !important;
}

.filterActive {
  box-shadow: inset 0 0 0 1px var(--mantine-color-blue-5) !important;
}

.button {
  max-width: 200px;

  & :global(.euiFilterButton__notification) {
    background-color: var(--mantine-color-blue-6);
  }

  & :global(.euiFilterButton-hasActiveFilters) {
    font-weight: 600;
  }

  & :global(.euiFilterButton__textShift) {
    min-width: 30px;
    font-size: 13px;
  }
}

.range {
  height: auto !important;
  display: grid !important;
  grid-template-columns: 20px 1fr 10px 1fr 30px;
  grid-template-areas:
    "minInput minInput . maxInput maxInput"
    "minLabel range range range maxLabel";

  & :global(.euiRange__horizontalSpacer) {
    display: none;
  }

  & :global(.euiFormControlLayout input) {
    width: 100% !important;
  }

  & :global(.euiFormControlLayout:first-of-type) {
    grid-area: minInput;
  }

  & :global(.euiFormControlLayout:last-of-type) {
    grid-area: maxInput;
  }

  & :global(.euiRangeLabel--min) {
    grid-area: minLabel;
  }

  & :global(.euiRangeLabel--max) {
    grid-area: maxLabel;
  }

  & :global(.euiRangeTrack) {
    grid-area: range;
  }
}
