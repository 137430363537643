.body {
  display: flex;
}

.track {
  min-width: 47px !important;
  padding-inline: 3px !important;

  &[data-error] {
    background: var(--mantine-color-red-2);
    color: var(--mantine-color-red-6);

    > :first-child {
      border-color: var(--mantine-color-red-4);
    }
  }
}

.trackLabel {
  font-size: 11px;
}

.label {
  font-weight: 500;
}
